import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";

const Container = styled.div`
  background: #f8f8f8;
  border-radius: 20px;
  max-width: 100%;
  height: 100%;
  margin: auto;
  padding: 20px;
  padding-bottom: 0;
  padding-top: 0;
  font-family: sans-serif;
  text-align: center;
`;

const CameraFrame = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreTag = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffffcc;
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
`;

const RankInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  font-size: 14px;
  color: #333;
`;

const LeaderboardB = styled.div`
  background: #fff;
  border-radius: 16px;
  padding: 16px;
  text-align: left;
`;

const LeaderboardTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #555;
`;

const LeaderboardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const Name = styled.span`
  font-weight: 500;
`;

const Score = styled.span`
  font-weight: bold;
`;

const ResetTimer = styled.div`
  font-size: 12px;
  color: #999;
  text-align: right;
  margin-bottom: 10px;
`;

const Leaderboard = ({
  currentUserData,
  topUsers,
  currentUserRank,
  totalUsersCount,
}) => {
  //   countdown
  const getTimeLeft = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();
    const now = new Date().getTime();
    const difference = countDownDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(
    getTimeLeft("May 15, 2025 00:00:00")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getTimeLeft("May 15, 2025 00:00:00"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function formatNumber(value) {
    if (value >= 1000000000) {
      const formattedValue = (value / 1000000000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2) + "B"
        : formattedValue + "B";
    } else if (value >= 1000000) {
      const formattedValue = (value / 1000000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2) + "M"
        : formattedValue + "M";
    } else if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2) + "K"
        : formattedValue + "K";
    } else {
      return value.toString();
    }
  }
  return (
    <Container>
      <CameraFrame>
        <h4>{currentUserData.name}</h4>
      </CameraFrame>

      <RankInfo>
        <div>
          <div>
            <strong>Your Rank</strong>
          </div>
          <div>
            {" "}
            {currentUserRank && (
              <p style={{ marginTop: "1rem", fontWeight: "bold" }}>
                #{currentUserRank} out of {formatNumber(totalUsersCount + 2100)}{" "}
                {}
              </p>
            )}
          </div>
        </div>
        <div>
          <div>
            <strong>Your score (coins)</strong>
          </div>
          <div>
            <p style={{ marginTop: "1rem", fontWeight: "bold" }}>
              {currentUserData.totalReferrals} 🪙
            </p>
          </div>
        </div>
      </RankInfo>

      <LeaderboardB>
        <LeaderboardTitle>Leaderboard</LeaderboardTitle>
        <ResetTimer>
          {" "}
          ⏳ {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
          {timeLeft.seconds}s
        </ResetTimer>
        {topUsers.map((user, index) => {
          const getEmoji = (i) => {
            if (i === 0) return "🥇";
            if (i === 1) return "🥈";
            if (i === 2) return "🥉";
            return "";
          };

          return (
            <LeaderboardItem key={index}>
              <Name>
                {`#${index + 1} ${user.name}`} {getEmoji(index)}
              </Name>
              <Score>{formatNumber(user.totalReferrals)} 🪙</Score>
            </LeaderboardItem>
          );
        })}
      </LeaderboardB>
    </Container>
  );
};

export default Leaderboard;
