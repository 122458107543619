import React from "react";
import styled from "styled-components";

// banner
import banner from "./Images/banner.png";

// css
import "../App.css";

const Seller = () => {
  return (
    <Mother className="gbvkmkrf">
      <SmartPhoneAnimator>
        <AnimatorRows>
          <SmartPhoneContent>
            <div>
              <span></span>
              <span>
                <h1>Camello, what's vogue today?</h1>
                <h4>Expericence the fresh way of ecommerce , coming soon!</h4>
              </span>
            </div>
          </SmartPhoneContent>
          <SmartPhone>
            <Deave dataImage={banner}></Deave>
          </SmartPhone>
        </AnimatorRows>
      </SmartPhoneAnimator>
    </Mother>
  );
};

const SmartPhoneContent = styled.div`
  width: 50%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 950px) {
    height: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  div {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    margin-bottom: 2px;
    margin-top: 2px;
    span {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 5px;
      justify-content: center;
      svg {
        margin-top: 5px;
      }
      h1 {
        color: #000;
        font-size: 60px;
        line-height: 3.6rem;
        letter-spacing: -2.5px;
        margin: 0;
        font-weight: 500;
        margin-top: 5px;
      }
      h4 {
        font-size: 20px;
        color: #3c4043;
        letter-spacing: -0.5px;
        font-weight: 400;
        margin: 0;
        margin-top: 15px;
        line-height: 1.3rem;
        @media only screen and (max-width: 950px) {
          height: auto;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
`;

const Deave = styled.div`
  background-image: url(${(props) => props.dataImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const SmartPhone = styled.div`
  width: 45%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;
  margin-bottom: 30px;
  div {
    width: 363px;
    height: 95%;
    border-radius: 36px;
    overflow: hidden;
    position: relative;
  }
  @media only screen and (max-width: 950px) {
    height: auto;
    width: 100%;
    justify-content: center;
    div {
      height: 583px;
    }
  }
`;

const AnimatorRows = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 950px) {
    height: auto;
    justify-content: center;
    flex-direction: column;
  }
`;

const SmartPhoneAnimator = styled.div`
  width: 80%;
  flex: 0 0 620px;
  background: transpar;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 950px) {
    height: auto;
    margin-top: 40px;
  }
  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const Mother = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  @media only screen and (max-width: 950px) {
    justify-content: flex-start;
  }
`;

export default Seller;
