import "./App.css";
import Home from "./Components/Home";
import Seller from "./Components/Seller";
import { useState, useEffect } from "react";
import styled from "styled-components";

function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Force repaint only for Instagram's in-app browser
    if (userAgent.includes("Instagram")) {
      setTimeout(() => {
        setLoaded(true); // Trigger a state change to force re-render
      }, 100); // Small delay to ensure re-render
    } else {
      setLoaded(true);
    }
  }, []);

  return (
    <AppMom
      style={{
        opacity: loaded ? 1 : 0,
        transition: "opacity 0.2s ease-in-out",
      }}
      className="App"
    >
      <Home />
    </AppMom>
  );
}

const AppMom = styled.div`
  width: 100%;
  height: 100vh;
  padding:0;
`;

export default App;
