import React from "react";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { Snackbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// banner
import fashionbanner from "./Images/fash.jpg";

// css
import "../App.css";

// components
import Leaderboard from "./Componennts/Leaderboard";

// videos
import LandingPropertiesmp4 from "./Videos/landing_property.mp4";

// icons
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RedditIcon from "@mui/icons-material/Reddit";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import CameraIcon from "@mui/icons-material/Camera";

const Home = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userDocId, setUserDocId] = useState(null); // track document ID
  const [currentUserData, setCurrentUserData] = useState(null); // fetched separately
  const [joinState, setJoinState] = useState(false);
  const [loginState, setLoginState] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refCodeFromUrl = urlParams.get("ref"); // 👈 match this with URL key
    if (refCodeFromUrl) setReferralCode(refCodeFromUrl);
  }, []);

  console.log(referralCode);

  // 👇 Restore userDocId from localStorage
  useEffect(() => {
    const storedDocId = localStorage.getItem("userDocId");
    if (storedDocId) {
      setUserDocId(storedDocId);
    }
  }, []);

  // 👇 Optional: handle Firebase session checking
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        localStorage.removeItem("userDocId");
        setUserDocId(null);
        setCurrentUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // 👇 Fetch current user data when userDocId is available
  const [userLoading, setUserLoading] = useState(false); // 👈 Loading state
  useEffect(() => {
    if (!userDocId) return;

    const fetchUserData = async () => {
      setUserLoading(true); // start loading
      try {
        const docSnap = await getDoc(doc(db, "NewWaitlist", userDocId));
        if (docSnap.exists()) {
          setCurrentUserData(docSnap.data());
          console.log("Logged in as:", docSnap.data().name);
        }
      } catch (err) {
        console.error("Failed to fetch user data:", err);
      } finally {
        setUserLoading(false); // stop loading
      }
    };

    fetchUserData();
  }, [userDocId]);

  const generateReferralCode = (email) => {
    return email.split("@")[0] + Math.random().toString(36).substring(2, 6);
  };
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const MIN_PASSWORD_LENGTH = 6;

    // Validate email format
    if (!emailRegex.test(email)) {
      setMessage("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    // Validate password strength
    if (password.length < MIN_PASSWORD_LENGTH) {
      setMessage(
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters.`
      );
      setLoading(false);
      return;
    }

    // ✅ Validate confirm password match
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      setLoading(false);
      return;
    }

    try {
      const emailQuery = query(
        collection(db, "NewWaitlist"),
        where("email", "==", email)
      );
      const existing = await getDocs(emailQuery);

      if (!existing.empty) {
        setMessage("This email is already registered.");
        setLoading(false);
        return;
      }

      const newReferralCode = generateReferralCode(email);
      const referredBy = referralCode || null;

      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const token = await user.getIdToken();
      document.cookie = `sessionToken=${token}; path=/; max-age=${
        60 * 60 * 24 * 365
      }`;

      const addedDoc = await addDoc(collection(db, "NewWaitlist"), {
        name,
        email,
        password,
        referralCode: newReferralCode,
        referredBy,
        totalReferrals: 0,
        joinedAt: serverTimestamp(),
      });

      setUserDocId(addedDoc.id);
      localStorage.setItem("userDocId", addedDoc.id);

      if (referredBy) {
        const refQuery = query(
          collection(db, "NewWaitlist"),
          where("referralCode", "==", referredBy)
        );
        const refSnapshot = await getDocs(refQuery);
        if (!refSnapshot.empty) {
          const refDoc = refSnapshot.docs[0];
          const refData = refDoc.data();

          await updateDoc(refDoc.ref, {
            totalReferrals: (refData.totalReferrals || 0) + 1,
          });
        }
      }

      setMessage("Successfully joined the waitlist! 🎉");
      setJoinState(false);
      setName("");
      setEmail("");
      setPassword("");
      setReferralCode("");
    } catch (err) {
      console.error(err);
      setMessage("Something went wrong. Try again.");
    } finally {
      setLoading(false);
    }
  };

  // copy referral
  const [copied, setCopied] = useState(false);

  const referralURL = currentUserData?.referralCode
    ? `${window.location.origin}/?ref=${currentUserData?.referralCode}`
    : "";

  const handleCopy = () => {
    navigator.clipboard.writeText(referralURL);
    setCopied(true);
  };

  // top users in the waitlist
  const [topUsers, setTopUsers] = useState([]);
  const [currentUserRank, setCurrentUserRank] = useState(null);
  const [totalUsersCount, setTotalUsersCount] = useState(0); // 👈 New state

  useEffect(() => {
    const fetchLeaderboard = async () => {
      // 1. Fetch top 10 users
      const topSnapshot = await getDocs(
        query(
          collection(db, "NewWaitlist"),
          orderBy("totalReferrals", "desc"),
          limit(10)
        )
      );
      const top10 = topSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTopUsers(top10);

      // 2. Fetch all users to determine rank and count
      const allSnapshot = await getDocs(
        query(collection(db, "NewWaitlist"), orderBy("totalReferrals", "desc"))
      );
      const allUsers = allSnapshot.docs.map((doc) => doc.id);

      // 👇 Total users count
      setTotalUsersCount(allUsers.length);

      // 👇 Find current user's rank
      const currentIndex = allUsers.findIndex((id) => id === userDocId);
      if (currentIndex !== -1) {
        setCurrentUserRank(currentIndex + 1);
      }
    };

    if (userDocId) fetchLeaderboard();
  }, [userDocId]);

  const [totalheadcounts, setTotalHeadCounts] = useState(0);
  useEffect(() => {
    const fetchTotalUsersCount = async () => {
      try {
        const snapshot = await getDocs(collection(db, "NewWaitlist"));
        setTotalHeadCounts(snapshot.docs.length);
      } catch (err) {
        console.error("Error fetching total users:", err);
      }
    };

    fetchTotalUsersCount();
  }, []);

  // logging system
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [loginLoading, setLoginLoading] = useState(false); // 👈 Loading state

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginMessage("");
    setLoginLoading(true); // start loading

    try {
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      const user = userCredential.user;

      const token = await user.getIdToken();
      document.cookie = `sessionToken=${token}; path=/; max-age=${
        60 * 60 * 24 * 365
      }`;

      const userDocQuery = await getDocs(
        query(collection(db, "NewWaitlist"), where("email", "==", loginEmail))
      );

      if (!userDocQuery.empty) {
        const userDoc = userDocQuery.docs[0];
        localStorage.setItem("userDocId", userDoc.id);
        setUserDocId(userDoc.id);
        setLoginMessage("Login successful ✅");
        setLoginState(false);
      } else {
        setLoginMessage("User data not found in Firestore.");
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginMessage("Invalid email or password ❌");
    } finally {
      setLoginLoading(false); // stop loading
    }
  };

  // logout
  const refNo = useRef(null);
  const buttonRefNo = useRef(null);
  const [createOutfitBox, setCreateOutfitBox] = useState(false);
  const handleClickOut = (event) => {
    if (
      refNo.current &&
      !refNo.current?.contains(event.target) &&
      !buttonRefNo.current?.contains(event.target)
    ) {
      setCreateOutfitBox(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOut);
    return () => {
      document.removeEventListener("mousedown", handleClickOut);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      document.cookie = "sessionToken=; path=/; max-age=0"; // remove session token
      localStorage.removeItem("userDocId"); // optional cleanup
      window.location.reload(); // or navigate to login/home page
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // Share on social media
  function shareOnTwitter(referralCode) {
    const tweetText = encodeURIComponent(
      `Join this amazing waitlist! Use my referral code and let’s grow together 🚀`
    );
    const referralUrl = encodeURIComponent(
      `${window.location.origin}/?ref=${referralCode}`
    );

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${referralUrl}`;

    window.open(
      twitterShareUrl,
      "_blank",
      "noopener,noreferrer,width=600,height=400"
    );
  }
  function shareOnWhatsApp(referralCode) {
    const message = encodeURIComponent(
      `Join this amazing waitlist using my referral code and let’s grow together 🚀\n${window.location.origin}/?ref=${referralCode}`
    );

    const whatsappShareUrl = `https://wa.me/?text=${message}`;

    window.open(
      whatsappShareUrl,
      "_blank",
      "noopener,noreferrer,width=600,height=400"
    );
  }

  function shareOnLinkedIn(referralCode) {
    const message = encodeURIComponent(
      `Join this amazing waitlist using my referral code and let’s grow together 🚀`
    );
    const referralUrl = encodeURIComponent(
      `${window.location.origin}/?ref=${referralCode}`
    );

    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${referralUrl}&summary=${message}`;

    window.open(
      linkedInShareUrl,
      "_blank",
      "noopener,noreferrer,width=600,height=600"
    );
  }

  function shareOnFacebook(referralCode) {
    const referralUrl = encodeURIComponent(
      `${window.location.origin}/?ref=${referralCode}`
    );

    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${referralUrl}`;

    window.open(
      facebookShareUrl,
      "_blank",
      "noopener,noreferrer,width=600,height=600"
    );
  }

  function shareOnReddit(referralCode) {
    const title = encodeURIComponent(
      "Join this amazing waitlist with my referral code!"
    );
    const referralUrl = encodeURIComponent(
      `${window.location.origin}/?ref=${referralCode}`
    );

    const redditShareUrl = `https://www.reddit.com/submit?url=${referralUrl}&title=${title}`;

    window.open(
      redditShareUrl,
      "_blank",
      "noopener,noreferrer,width=600,height=600"
    );
  }

  function formatNumber(value) {
    if (value >= 1000000000) {
      const formattedValue = (value / 1000000000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2) + "B"
        : formattedValue + "B";
    } else if (value >= 1000000) {
      const formattedValue = (value / 1000000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2) + "M"
        : formattedValue + "M";
    } else if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2) + "K"
        : formattedValue + "K";
    } else {
      return value.toString();
    }
  }

  if (userLoading) {
    return (
      <MotherCarmel>
        {" "}
        <CircularProgress />
      </MotherCarmel>
    );
  }

  return (
    <Mother className="gbvkmkrf">
      <Snackbar
        open={copied}
        autoHideDuration={5000} // closes in 5 seconds
        onClose={() => setCopied(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message="Copied!"
      ></Snackbar>
      <Header>
        <HeaderKids>
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="95"
            height="70"
            viewBox="0 0 300.000000 115.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            {" "}
            <g
              transform="translate(0.000000,115.000000) scale(0.050000,-0.050000)"
              fill="#000000"
              stroke="none"
            >
              {" "}
              <path d="M4520 1946 c-196 -72 -380 -597 -380 -1088 0 -211 -7 -224 -160 -316 -90 -54 -238 -56 -301 -5 l-46 37 105 35 c250 84 363 329 215 469 -195 183 -532 -52 -533 -371 0 -124 -88 -226 -161 -186 -47 24 -48 80 -9 259 99 452 -150 503 -391 80 -49 -87 -59 -97 -50 -50 49 254 34 330 -62 330 -113 0 -227 -102 -321 -288 -67 -131 -75 -102 -27 98 43 179 41 190 -37 190 -107 0 -161 -112 -194 -405 -11 -98 -23 -124 -81 -175 -133 -116 -162 -67 -117 198 45 271 39 310 -50 319 -38 3 -77 19 -86 35 -87 154 -492 -186 -494 -416 -1 -131 -465 -212 -672 -118 -405 184 -222 1022 224 1022 134 0 168 -40 168 -197 0 -187 66 -239 171 -134 41 41 49 68 49 171 0 240 -115 349 -368 350 -730 0 -1003 -1159 -332 -1412 144 -55 507 -46 681 17 l112 40 45 -47 c72 -74 225 -68 305 12 l60 60 50 -60 c65 -77 169 -82 272 -12 l71 48 21 -48 c16 -35 38 -48 80 -48 75 0 87 17 149 221 110 357 204 415 184 111 -19 -273 2 -337 106 -328 69 6 72 10 114 146 98 320 250 514 223 285 -40 -330 -40 -322 8 -382 62 -79 235 -71 348 15 l74 56 43 -39 c146 -133 415 -116 612 38 48 37 48 37 84 -21 90 -149 316 -139 491 22 43 39 43 39 56 -2 47 -147 395 -162 482 -21 9 15 28 9 56 -17 276 -251 667 63 572 458 -105 440 -662 278 -662 -193 0 -173 -239 -251 -298 -97 -16 43 -6 69 63 172 385 566 458 1249 127 1187 -210 -39 -415 -589 -412 -1109 2 -193 -27 -254 -149 -312 -172 -81 -250 50 -133 223 404 595 468 1325 105 1193z m72 -198 c38 -149 -41 -472 -179 -738 l-63 -120 -6 107 c-19 325 194 967 248 751z m556 20 c49 -128 -13 -419 -150 -701 -84 -172 -86 -175 -94 -107 -32 282 178 979 244 808z m-3371 -968 c-47 -235 -80 -300 -155 -300 -65 0 -82 28 -82 137 1 151 130 343 231 343 l42 0 -36 -180z m2050 136 c37 -58 -8 -138 -107 -191 l-90 -47 -7 43 c-23 154 135 305 204 195z m1822 -20 c81 -117 6 -416 -105 -416 -84 0 -144 158 -116 305 26 138 156 203 221 111z" />{" "}
            </g>{" "}
          </svg>
          {currentUserData ? (
            <button
              ref={buttonRefNo}
              onClick={() => {
                setCreateOutfitBox(!createOutfitBox);
              }}
            >
              {currentUserData.name}
              {createOutfitBox && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLogout();
                  }}
                  ref={refNo}
                >
                  <span>Log Out</span>
                </div>
              )}
            </button>
          ) : (
            <button
              onClick={() => {
                setLoginState(true);
              }}
            >
              Log In
            </button>
          )}
        </HeaderKids>
      </Header>
      <SmartPhoneAnimator>
        <AnimatorRows>
          <SmartPhoneContent>
            <div>
              <span></span>
              <span>
                <h1>Camello, what's vogue today?</h1>
                <h4>
                  Experience a fresh new way of fashion-forward social shopping!
                  🛍️ Join our waitlist to be among the first to explore the beta
                  drop as we go live.
                </h4>
                <p>
                  <strong>Exciting Offer:</strong> Use your referral code after
                  signing up to invite friends!
                </p>
                <p>
                  <>
                    🏆 Top 10 referrers will win a{" "}
                    <strong>free outfit from our Summer Collection</strong>.
                  </>
                  <br />
                  <>
                    ✨ For every successful referral, you'll earn 1 point = 1
                    coin as an in-app reward.
                  </>
                  <br />
                  <>
                    These coins will be delivered to your Camello account
                    (linked to your signup email) once the site goes live — and
                    you can use them right inside the app to shop!
                  </>
                </p>
                <p>
                  ⏳ The waitlist is open till{" "}
                  <strong>15th May 2025, 12 AM</strong> — so spread the word
                  and start referring now!
                </p>{" "}
                {currentUserData && (
                  <ListChoose>
                    <p>Share the referral url </p>
                  </ListChoose>
                )}
                {currentUserData ? (
                  <>
                    <ShareBoard>
                      <button
                        onClick={() => {
                          handleCopy();
                        }}
                      >
                        <ContentCopyIcon fontSize="medium" />
                        <h5>
                          {window.location.origin}/?ref=
                          {currentUserData?.referralCode}
                        </h5>
                      </button>
                    </ShareBoard>
                    <SelectSizesOpt>
                      <button
                        onClick={() => {
                          shareOnTwitter(currentUserData?.referralCode);
                        }}
                      >
                        <XIcon fontSize="medium" />
                      </button>
                      <button
                        onClick={() => {
                          shareOnWhatsApp(currentUserData?.referralCode);
                        }}
                      >
                        <WhatsAppIcon fontSize="medium" />
                      </button>
                      <button
                        onClick={() => {
                          shareOnLinkedIn(currentUserData?.referralCode);
                        }}
                      >
                        <LinkedInIcon fontSize="medium" />
                      </button>
                      <button
                        onClick={() => {
                          shareOnFacebook(currentUserData?.referralCode);
                        }}
                      >
                        <FacebookOutlinedIcon fontSize="medium" />
                      </button>
                      <button
                        onClick={() => {
                          shareOnReddit(currentUserData?.referralCode);
                        }}
                      >
                        <RedditIcon fontSize="medium" />
                      </button>
                    </SelectSizesOpt>
                  </>
                ) : (
                  <>
                    <ListMail>
                      <button
                        onClick={() => {
                          setJoinState(!joinState);
                        }}
                      >
                        <span> {"Join the waitlist"}</span>
                      </button>
                    </ListMail>
                    <p>
                      {" "}
                      {formatNumber(totalheadcounts + 2100)} {}
                      fashion lovers are already on board. Your style deserves a
                      spot too.
                    </p>
                  </>
                )}
              </span>
            </div>
          </SmartPhoneContent>
          <SmartPhone>
            {currentUserData ? (
              <Deave>
                <Leaderboard
                  currentUserData={currentUserData}
                  topUsers={topUsers}
                  totalUsersCount={totalUsersCount}
                  currentUserRank={currentUserRank}
                />
              </Deave>
            ) : (
              <Deave>
                <CameraIcon fontSize="large" />
                <video
                  src={LandingPropertiesmp4}
                  autoPlay
                  muted
                  loop
                  playsInline
                  controls={false}
                ></video>
              </Deave>
            )}{" "}
          </SmartPhone>
        </AnimatorRows>
      </SmartPhoneAnimator>
      <Footer
        style={{
          background: "#fff",
        }}
      >
        <FooterHeader>
          <div style={{ color: "#757272 " }}>© 2025 Camello</div>
          <div>
            {/* <a
                onClick={() => {
                  setIsLanguageModal(true);
                }}
                href="/#languages"
              >
                <LanguageIcon fontSize="small" />
                {selectedLanguage === "hi" && <>हिन्दी</>}
                {selectedLanguage === "en" && <>English</>}
              </a> */}
            <a
              href="https://about.camelloapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              About
            </a>
            <a
              href="https://blogs.camelloapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blogs
            </a>
            <a
              href="https://help.camelloapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Center
            </a>
            <a
              href="https://careers.camelloapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Careers
            </a>
            <div style={{ color: "#757272 " }}>India</div>
          </div>
        </FooterHeader>
      </Footer>
      {/* {!currentUserData && (
        <AnimatorCover cover={fashionbanner}>
          <h1>
            {formatNumber(totalheadcounts + 500)} {}
            fashion lovers are already on board. Your style deserves a spot too.
          </h1>
        </AnimatorCover>
      )} */}
      {joinState && (
        <AboutAccountMoreOptions>
          <AboutThisAccountContainer
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <TagBar>
              <div>
                <span
                  style={{
                    color: "#000",
                  }}
                >
                  Join waitlist
                </span>
              </div>
              <div>
                <IconButton
                  color={"light"}
                  aria-label="menu"
                  size="medium"
                  onClick={() => {
                    setJoinState(!joinState);
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "#000",
                    }}
                    fontSize="medium"
                  />
                </IconButton>
              </div>
            </TagBar>
            <AboutScrollerComponent>
              <ListMailInputs>
                <form onSubmit={handleSubmit}>
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="95"
                    height="70"
                    viewBox="0 0 300.000000 115.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    {" "}
                    <g
                      transform="translate(0.000000,115.000000) scale(0.050000,-0.050000)"
                      fill="#000000"
                      stroke="none"
                    >
                      {" "}
                      <path d="M4520 1946 c-196 -72 -380 -597 -380 -1088 0 -211 -7 -224 -160 -316 -90 -54 -238 -56 -301 -5 l-46 37 105 35 c250 84 363 329 215 469 -195 183 -532 -52 -533 -371 0 -124 -88 -226 -161 -186 -47 24 -48 80 -9 259 99 452 -150 503 -391 80 -49 -87 -59 -97 -50 -50 49 254 34 330 -62 330 -113 0 -227 -102 -321 -288 -67 -131 -75 -102 -27 98 43 179 41 190 -37 190 -107 0 -161 -112 -194 -405 -11 -98 -23 -124 -81 -175 -133 -116 -162 -67 -117 198 45 271 39 310 -50 319 -38 3 -77 19 -86 35 -87 154 -492 -186 -494 -416 -1 -131 -465 -212 -672 -118 -405 184 -222 1022 224 1022 134 0 168 -40 168 -197 0 -187 66 -239 171 -134 41 41 49 68 49 171 0 240 -115 349 -368 350 -730 0 -1003 -1159 -332 -1412 144 -55 507 -46 681 17 l112 40 45 -47 c72 -74 225 -68 305 12 l60 60 50 -60 c65 -77 169 -82 272 -12 l71 48 21 -48 c16 -35 38 -48 80 -48 75 0 87 17 149 221 110 357 204 415 184 111 -19 -273 2 -337 106 -328 69 6 72 10 114 146 98 320 250 514 223 285 -40 -330 -40 -322 8 -382 62 -79 235 -71 348 15 l74 56 43 -39 c146 -133 415 -116 612 38 48 37 48 37 84 -21 90 -149 316 -139 491 22 43 39 43 39 56 -2 47 -147 395 -162 482 -21 9 15 28 9 56 -17 276 -251 667 63 572 458 -105 440 -662 278 -662 -193 0 -173 -239 -251 -298 -97 -16 43 -6 69 63 172 385 566 458 1249 127 1187 -210 -39 -415 -589 -412 -1109 2 -193 -27 -254 -149 -312 -172 -81 -250 50 -133 223 404 595 468 1325 105 1193z m72 -198 c38 -149 -41 -472 -179 -738 l-63 -120 -6 107 c-19 325 194 967 248 751z m556 20 c49 -128 -13 -419 -150 -701 -84 -172 -86 -175 -94 -107 -32 282 178 979 244 808z m-3371 -968 c-47 -235 -80 -300 -155 -300 -65 0 -82 28 -82 137 1 151 130 343 231 343 l42 0 -36 -180z m2050 136 c37 -58 -8 -138 -107 -191 l-90 -47 -7 43 c-23 154 135 305 204 195z m1822 -20 c81 -117 6 -416 -105 -416 -84 0 -144 158 -116 305 26 138 156 203 221 111z" />{" "}
                    </g>{" "}
                  </svg>
                  <input
                    type="text"
                    placeholder="Enter Your Name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="Enter Your Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />

                  <button type="submit" disabled={loading}>
                    <span> {loading ? "Joining..." : "Join"}</span>
                  </button>
                  {message && <h5>{message}</h5>}
                </form>
              </ListMailInputs>
            </AboutScrollerComponent>
          </AboutThisAccountContainer>
        </AboutAccountMoreOptions>
      )}{" "}
      {/* LogIn */}
      {loginState && (
        <AboutAccountMoreOptions>
          <AboutThisAccountContainer>
            <TagBar>
              {" "}
              <div>
                <span
                  style={{
                    color: "#000",
                  }}
                >
                  Log In
                </span>
              </div>
              <div>
                <IconButton
                  color={"light"}
                  aria-label="menu"
                  size="medium"
                  onClick={() => {
                    setLoginState(!loginState);
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "#000",
                    }}
                    fontSize="medium"
                  />
                </IconButton>
              </div>
            </TagBar>
            <AboutScrollerComponent>
              <ListMailInputs>
                <form onSubmit={handleLogin}>
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="95"
                    height="70"
                    viewBox="0 0 300.000000 115.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    {" "}
                    <g
                      transform="translate(0.000000,115.000000) scale(0.050000,-0.050000)"
                      fill="#000000"
                      stroke="none"
                    >
                      {" "}
                      <path d="M4520 1946 c-196 -72 -380 -597 -380 -1088 0 -211 -7 -224 -160 -316 -90 -54 -238 -56 -301 -5 l-46 37 105 35 c250 84 363 329 215 469 -195 183 -532 -52 -533 -371 0 -124 -88 -226 -161 -186 -47 24 -48 80 -9 259 99 452 -150 503 -391 80 -49 -87 -59 -97 -50 -50 49 254 34 330 -62 330 -113 0 -227 -102 -321 -288 -67 -131 -75 -102 -27 98 43 179 41 190 -37 190 -107 0 -161 -112 -194 -405 -11 -98 -23 -124 -81 -175 -133 -116 -162 -67 -117 198 45 271 39 310 -50 319 -38 3 -77 19 -86 35 -87 154 -492 -186 -494 -416 -1 -131 -465 -212 -672 -118 -405 184 -222 1022 224 1022 134 0 168 -40 168 -197 0 -187 66 -239 171 -134 41 41 49 68 49 171 0 240 -115 349 -368 350 -730 0 -1003 -1159 -332 -1412 144 -55 507 -46 681 17 l112 40 45 -47 c72 -74 225 -68 305 12 l60 60 50 -60 c65 -77 169 -82 272 -12 l71 48 21 -48 c16 -35 38 -48 80 -48 75 0 87 17 149 221 110 357 204 415 184 111 -19 -273 2 -337 106 -328 69 6 72 10 114 146 98 320 250 514 223 285 -40 -330 -40 -322 8 -382 62 -79 235 -71 348 15 l74 56 43 -39 c146 -133 415 -116 612 38 48 37 48 37 84 -21 90 -149 316 -139 491 22 43 39 43 39 56 -2 47 -147 395 -162 482 -21 9 15 28 9 56 -17 276 -251 667 63 572 458 -105 440 -662 278 -662 -193 0 -173 -239 -251 -298 -97 -16 43 -6 69 63 172 385 566 458 1249 127 1187 -210 -39 -415 -589 -412 -1109 2 -193 -27 -254 -149 -312 -172 -81 -250 50 -133 223 404 595 468 1325 105 1193z m72 -198 c38 -149 -41 -472 -179 -738 l-63 -120 -6 107 c-19 325 194 967 248 751z m556 20 c49 -128 -13 -419 -150 -701 -84 -172 -86 -175 -94 -107 -32 282 178 979 244 808z m-3371 -968 c-47 -235 -80 -300 -155 -300 -65 0 -82 28 -82 137 1 151 130 343 231 343 l42 0 -36 -180z m2050 136 c37 -58 -8 -138 -107 -191 l-90 -47 -7 43 c-23 154 135 305 204 195z m1822 -20 c81 -117 6 -416 -105 -416 -84 0 -144 158 -116 305 26 138 156 203 221 111z" />{" "}
                    </g>{" "}
                  </svg>{" "}
                  <input
                    type="email"
                    placeholder="Email"
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    required
                  />
                  <button type="submit">
                    {loginLoading ? (
                      <span>Logging in..</span>
                    ) : (
                      <span>Log In</span>
                    )}
                  </button>
                  {loginMessage && <h5>{loginMessage}</h5>}
                </form>
              </ListMailInputs>
            </AboutScrollerComponent>
          </AboutThisAccountContainer>
        </AboutAccountMoreOptions>
      )}
    </Mother>
  );
};

const Footer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  @media screen and (max-width: 815px) {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const FooterHeader = styled.div`
  width: 80%;
  min-height: 70px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 675px) {
    width: 90%;
  }
  @media screen and (max-width: 815px) {
    line-height: 15px;
    row-gap: 4px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    padding-top: 10px;
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 10px;
      row-gap: 5px;
    }
  }

  div {
    margin: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  a {
    text-decoration: none;
    margin: 5px;
    font-size: 14px;
    color: #757272 !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    svg {
      margin: 2px;
    }
  }
`;

const AboutScrollerComponent = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  background: transparent;
  overflow-y: scroll;
  border-top: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const TagBar = styled.div`
  width: 100%;
  height: 60px;
  background: transparent;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  div {
    margin: 15px;
    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

const AboutThisAccountContainer = styled.div`
  width: 528px;
  height: 566px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  @media only screen and (max-width: 568px) {
    width: 98%;
    border-radius: 20px 20px 0 0;
  }
`;

const AboutAccountMoreOptions = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.87);
  z-index: 58;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 1s;
  @media only screen and (max-width: 568px) {
    justify-content: flex-end;
  }
`;

const ListMailInputs = styled.div`
  width: 85% !important;
  height: auto;
  display: flex;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin-top: 10px;
  @media only screen and (max-width: 950px) {
    justify-content: center !important;
    align-items: center !important;
  }
  input {
    height: 42px;
    margin-top: 9px;
    border-radius: 50px;
    border: 1px solid #ddd;
    background: #f0f0f0;
    padding: 0px 11px;
    width: 100%;
    margin-right: 6px;
  }
  button:disabled {
    cursor: not-allowed;
  }
  button {
    padding: 0px 11px;
    margin-top: 9px;
    width: 100%;
    height: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #000;
    margin-right: 8px;
    outline: none;
    background: #000;
    span {
      font-size: 15px !important;
      color: #fff !important;
    }
  }
`;

const SelectSizesOpt = styled.div`
  width: 100%;
  height: auto;
  flex-wrap: wrap !important;
  margin-top: 15px;
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important ;
  text-align: center;
  @media only screen and (max-width: 950px) {
    justify-content: center !important;
    align-items: center !important;
  }
  button {
    border: 1px solid #000;
    height: 50px;
    width: 50px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
    position: relative;
    outline: none;
    padding: 0;
    background: #000;
    svg {
      color: #fff;
      margin: 0 !important;
    }
  }
`;

const ShareBoard = styled.div`
  width: 100% !important;
  height: auto;
  display: flex;
  align-items: flex-start !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin-top: 10px;
  button {
    width: 300px;
    padding: 0px 8px;
    background: #f8f8f8;
    border: 1px solid #ddd;
    height: 45px;
    cursor: pointer;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
    h5 {
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      overflow-wrap: break-word;
      text-align: start;
      min-width: auto;
      max-width: 280px;
    }
  }
  @media only screen and (max-width: 950px) {
    justify-content: center !important;
    align-items: center !important;
  }
`;

const ListMail = styled.div`
  width: 100% !important;
  height: auto;
  display: flex;
  align-items: flex-start !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin-top: 10px;
  @media only screen and (max-width: 950px) {
    justify-content: center !important;
    align-items: center !important;
  }
  input {
    height: 42px;
    margin-top: 9px;
    border-radius: 50px;
    border: 1px solid #ddd;
    background: #f0f0f0;
    padding: 0px 11px;
    width: 271px;
    margin-right: 6px;
  }
  button:disabled {
    cursor: not-allowed;
  }
  button {
    padding: 0px 11px;
    margin-top: 9px;
    width: 271px;
    height: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #000;
    margin-right: 8px;
    outline: none;
    background: #000;
    span {
      font-size: 15px !important;
      color: #fff !important;
    }
  }
`;

const ListChoose = styled.div`
  width: 100% !important;
  height: auto;
  display: flex;
  align-items: center !important;
  flex-direction: row;
  justify-content: flex-start !important;
  margin-top: 15px;
  @media only screen and (max-width: 950px) {
    justify-content: center !important;
  }
  P {
    margin: 0;
  }
  button {
    width: auto;
    height: 35px;
    border-radius: 50px;
    padding: 0px 8px;
    cursor: pointer;
    border: none;
    margin-right: 8px;
    outline: none;
    span {
      font-size: 15px !important;
      color: #000;
    }
  }
`;

const SmartPhoneContent = styled.div`
  width: 50%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 950px) {
    height: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    align-items: center;
  }

  div {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    margin-bottom: 2px;
    margin-top: 2px;
    span {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 5px;
      justify-content: center;
      svg {
        margin-top: 5px;
      }
      h1 {
        color: #1877f2;
        font-size: 40px;
        letter-spacing: -2.5px;
        margin: 0;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      h5 {
        font-size: 14px;
        color: #3c4043;
        letter-spacing: -0.5px;
        font-weight: 400;
        margin: 0;
        margin-top: 8px;
      }
      h4 {
        font-size: 20px;
        color: #3c4043;
        letter-spacing: -0.5px;
        font-weight: 400;
        margin: 0;
        margin-top: 0px;
        margin-bottom: 8px;
        line-height: 1.3rem;
        @media only screen and (max-width: 950px) {
          height: auto;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
`;

const Deave = styled.div`
  background-image: url(${(props) => props.dataimage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  width: 363px;
  height: 95%;
  border-radius: 36px;
  overflow: hidden;
  position: relative;
  svg {
    position: absolute;
    color: #fff;
    top: 19px;
    left: 17px;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 950px) {
    height: 583px;
  }
`;

const SmartPhone = styled.div`
  width: 45%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;
  margin-bottom: 30px;

  @media only screen and (max-width: 950px) {
    height: auto;
    width: 100%;
    justify-content: center;
  }
`;

const AnimatorRows = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 950px) {
    height: auto;
    justify-content: center;
    flex-direction: column;
  }
`;

const Header = styled.div`
  flex: 0 0 70px;
  width: 100%;
  background: #fff;
  margin-bottom: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderKids = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  @media only screen and (max-width: 950px) {
    width: 90%;
  }

  button {
    background: none;
    border: none;
    font-size: 21px;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    position: relative;
    div {
      width: 128px;
      height: 43px;
      border-radius: 25px;
      background: #fff;
      position: absolute;
      box-shadow: #ddd 0px 2px 5px;
      top: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      right: 0;
    }
  }
`;

const AnimatorCover = styled.div`
  width: 100%;
  flex: 0 0 700px;
  background-image: url(${(props) => props.cover});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 60px;
    color: #fff;
    text-align: center;
    letter-spacing: -2.5px;
    font-weight: 500;
  }
`;

const SmartPhoneAnimator = styled.div`
  width: 80%;
  flex: 0 0 620px;
  background: transpar;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 950px) {
    height: auto;
    margin-top: 0px;
    width: 90%;
  }
  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const MotherCarmel = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  padding: 0;
  @media only screen and (max-width: 950px) {
    justify-content: center;
  }
`;

const Mother = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 0;
  @media only screen and (max-width: 950px) {
    justify-content: flex-start;
  }
`;

export default Home;
