// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDssAwj8CRSfrHG9pQSm3F545Jfq1S7cVc",
  authDomain: "camello-68f5f.firebaseapp.com",
  projectId: "camello-68f5f",
  storageBucket: "camello-68f5f.firebasestorage.app",
  messagingSenderId: "404058968092",
  appId: "1:404058968092:web:1eb9f18a3a31c8c592cfd9",
  measurementId: "G-W4LE031QK5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app); // ✅ Add this line

export { db, auth }; // ✅ Export both
